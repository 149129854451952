<template>
  <tj-dialog
    v-model="visible"
    title="指派工程师"
    width="900px"
    @close="onClose"
  >
    <el-container>
      <el-main>
        <el-table :data="tableData">
          <el-table-column label="姓名" prop="name"></el-table-column>
          <el-table-column label="手机号" align="center">
            <template #default="{ row }">
              <el-link
                v-if="row.mobile"
                type="primary"
                :icon="Phone"
                :underline="false"
                :href="`tel:${row.mobile}`"
              >{{ row.mobile }}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="地区" prop="province"></el-table-column>
          <el-table-column label="启用/禁用" align="center" width="82">
            <template #default="{ row }">
              <el-tag v-if="row.is_enable" type="success">启用</el-tag>
              <el-tag v-else type="info">禁用</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="100">
            <template #default="{ row }">
              <el-button
                type="primary"
                :icon="Check"
                @click="submit(row.id)"
              >指派</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer height="auto">
        <tj-pagination
          v-model="page"
          layout="total,prev,pager,next,jumper"
          @change="getList"
        ></tj-pagination>
      </el-footer>
    </el-container>
    <template #footer>
      <el-button @click="close">关闭</el-button>
    </template>
  </tj-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { Phone, Check } from '@element-plus/icons-vue';
import { useDialog, usePagination } from '@tj/ui';
import request from '@/hooks/request';

const emit = defineEmits(['submit']);
const { page } = usePagination({ limit: 10 });
const tableData = ref([]);
let orderId = 0;

function getList() {
  request.get('/admin/engineer/user', {
    params: {
      status: 1,
      page: page.page,
      per_page: page.limit,
    },
  }).then((data) => {
    tableData.value = data.list;
    page.total = data.total;
  });
}

const {
  visible,
  open,
  close,
  onOpen,
} = useDialog();
onOpen((id) => {
  orderId = id;
  getList();
});
function onClose() {
  orderId = 0;
  tableData.value = [];
  page.page = 1;
}

function submit(engineerId) {
  request.post('/admin/company/orderAssignEngineer', {
    order_id: orderId,
    engineer_id: engineerId,
  }).then(() => {
    emit('submit');
    close();
  });
}

defineExpose({
  open,
});
</script>
